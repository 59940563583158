.App {
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.5rem;
  color: #121212;
}
.spacer {
  width: 120px;
}
a {
  font-weight: 400;
  text-decoration: none;
  color: #999999;
}

a.active {
  color: #121212;
  font-weight: bold;
}

a:hover {
  color: #121212;
}

header {
  display: grid;
  grid-template-columns: repeat(3, 10fr);
  align-content: center;
  padding: 48px 48px;
}
.header-links {
  display: flex;
  gap: 16px;
  align-items: center;
}
.header-links a {
  display: block;
  width: 3rem;
  text-align: center;
}

.logo {
  display: flex;
  place-self: center;
  height: 20px;
}
.logo img {
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .header {
    grid-template-columns: none;
    grid-template-rows: auto;
    margin: 24px;
    padding: 16px;
    gap: 16px;
  }
  .header-links {
    width: 100%;
    justify-content: space-around;
    margin-top: 16px;
  }
  .header-links a {
    width: 25%;
  }
  .logo {
    order: -1;
    width: 100%;
    place-content: center;
  }
}

.container {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
}

.boxes {
  width: 100%;
  max-width: 860px;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}
@media only screen and (max-width: 768px) {
  .boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
.boxes a {
  display: contents;
}
.work-card {
  background-color: gray;
  overflow: hidden;
  transition: all 1s;
  aspect-ratio: 1 / 1;
  display: flex;
  place-content: center;
}

.work-card:hover {
  cursor: pointer;
  opacity: 0.5;
}

.work-card img {
  height: 100%;
  width: auto;
}

.about {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 840px;
}
.about h1,
h2 {
  width: 100%;
}

.about-section {
  width: 100%;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  max-width: 840px;
}

.content,
.about,
.contact {
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
p {
  max-width: 640px;
}
h1 {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
}
h2 {
  font-size: 1.5rem;
}
p {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.25rem;
}
.about p {
  text-align: left;
}
h4 {
  font-size: 1rem;
  font-weight: 300;
  max-width: 480px;
}

.product-grid {
  display: grid;
  grid-auto-columns: auto;
  justify-items: center;
  gap: 24px;
  padding-top: 24px;
}
@media only screen and (max-width: 768px) {
  .product-grid {
    padding-top: 16px;
  }
}

.product {
  width: 100%;
  max-width: 860px;
}

a#mail {
  color: #6f6f6f;
}
a#mail:hover {
  color: #121212;
}
a#mail::before {
  content: "frida.mathlin";
}
a#mail::after {
  content: "gmail.com";
}

.loading {
  gap: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.skeleton-box {
  display: inline-block;
  height: 400px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
    rgb(200, 200, 200),
    rgba(200, 200, 200, 0.75)
  );
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    to right,
    rgba(99, 98, 95, 0.1),
    rgba(99, 98, 95, 0.02)
  );
  rotate: 1deg;
  animation: shimmer 1s infinite;
  content: "";
  filter: blur(22px);
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.skeleton-box:nth-child(2) {
  background-image: linear-gradient(
    rgba(200, 200, 200, 0.75),
    rgba(200, 200, 200, 0.25)
  );
}
.skeleton-box:nth-child(3) {
  background-image: linear-gradient(
    rgba(200, 200, 200, 0.25),
    rgba(200, 200, 200, 0)
  );
}

.masonry {
  column-gap: 1em;
}

.masonry-item {
  display: grid;
  break-inside: avoid;
  margin-bottom: 1em;
  background: rgb(238, 238, 238);
  background: radial-gradient(
    circle,
    rgba(238, 238, 238, 1) 0%,
    rgba(204, 204, 204, 1) 100%
  );
}

@media (min-width: 576px) {
  .masonry {
    column-count: 2;
  }
}
@media (min-width: 768px) {
  .masonry {
    column-count: 4;
  }
}
